var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "certifications-form" },
    [
      _c("div", { staticClass: "certifications-form__main-title" }, [
        _vm._v("Certifications"),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass:
            "certifications-list certifications-form__certifications-list",
        },
        _vm._l(_vm.certificateData, function (person, index) {
          return _c(
            "inputs-section-frame",
            {
              key: person.fullName,
              staticClass: "certifications-list__separate-certification-form",
              attrs: {
                tag: "li",
                "show-drag-handle": "",
                "show-delete-but": "",
              },
            },
            [
              _c("div", { staticClass: "form-elements-list" }, [
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "Certification Name",
                        "no-clear-button": "",
                      },
                      model: {
                        value: _vm.certificateData[index].name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.certificateData[index],
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "certificateData[index].name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "Organization",
                        "no-clear-button": "",
                      },
                      model: {
                        value: _vm.certificateData[index].organization,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.certificateData[index],
                            "organization",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "certificateData[index].organization",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "form-elements-list__form-element-wrap",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-elements-list__form-element-wrap" },
                  [
                    _c("input-custom", {
                      staticClass: "form-elements-list__text-input",
                      attrs: {
                        placeholder: "Add Proof letter",
                        type: "fileOrUrl",
                        "is-file": "",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "fs-button",
        {
          staticClass: "certifications-form__add-person-but",
          attrs: { secondary: "" },
        },
        [
          _c("fs-button-icon", {
            attrs: {
              icon: _vm.icons.interfaceElements.plusInCircleIcon,
              width: "1.5rem/1.6",
              height: "1.5rem/1.6",
              top: "0",
              color: "dark-blue",
            },
          }),
          _vm._v("\n    Add Certification\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c("cancel-n-action-btn-pair", {
        staticClass: "certifications-form__manage-buttons-panel",
        on: { cancel: function () {}, action: function () {} },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }