import { render, staticRenderFns } from "./general-information-edit.vue?vue&type=template&id=f2e75d66&scoped=true"
import script from "./general-information-edit.vue?vue&type=script&lang=js"
export * from "./general-information-edit.vue?vue&type=script&lang=js"
import style0 from "./general-information-edit.vue?vue&type=style&index=0&id=f2e75d66&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2e75d66",
  null
  
)

export default component.exports