<template>
  <div
    :class="{
      'articles-n-checkboxes': true,
      'articles-n-checkboxes--1-narrow-elements': (tagsMode || seasonsMode) && entities.length === 1,
      'articles-n-checkboxes--2-narrow-elements': (tagsMode || seasonsMode) && entities.length === 2,
      'articles-n-checkboxes--3-narrow-elements': (tagsMode || seasonsMode) && entities.length === 3,

      'articles-n-checkboxes--1-wide-element': suppliersMode && entities.length === 1,
      'articles-n-checkboxes--2-wide-elements': suppliersMode && entities.length === 2,
      'articles-n-checkboxes--3-wide-elements': suppliersMode && entities.length === 3,

      'articles-n-checkboxes--1-line': suppliersMode && articles.length === 1,
      'articles-n-checkboxes--2-lines': suppliersMode && articles.length === 2,
      'articles-n-checkboxes--3-lines': suppliersMode && articles.length === 3,
      'articles-n-checkboxes--4-lines': suppliersMode && articles.length === 4,
      'articles-n-checkboxes--5-lines': suppliersMode && articles.length === 5,
      'articles-n-checkboxes--6-lines': suppliersMode && articles.length === 6,

      'articles-n-checkboxes--1-line-with-short-header': (tagsMode || seasonsMode) && articles.length === 1,
      'articles-n-checkboxes--2-lines-with-short-header': (tagsMode || seasonsMode) && articles.length === 2,
      'articles-n-checkboxes--3-lines-with-short-header': (tagsMode || seasonsMode) && articles.length === 3,
      'articles-n-checkboxes--4-lines-with-short-header': (tagsMode || seasonsMode) && articles.length === 4,
      'articles-n-checkboxes--5-lines-with-short-header': (tagsMode || seasonsMode) && articles.length === 5,
      'articles-n-checkboxes--6-lines-with-short-header': (tagsMode || seasonsMode) && articles.length === 6,
    }"
  >
    <!-- Scrollable table -->
    <section
      :class="{
        'scrollable-table': true,
        'scrollable-table--short-header': tagsMode || seasonsMode,
        'articles-n-checkboxes__scrollable-table': true,
      }"
    >
      <transition name="simple-fade-in-out-fast">
        <div
          v-if="entities.length > 4 && isLeftCurtainGradientVisible"
          class="scrollable-table__left-curtain-gradient"
        ></div>
      </transition>
      <transition name="simple-fade-in-out-fast">
        <div
          v-if="entities.length > 4 && isRightCurtainGradientVisible"
          class="scrollable-table__right-curtain-gradient"
        ></div>
      </transition>

      <transition name="simple-fade-in-out-fast">
        <div
          v-if="articles.length > 6 && isBottomCurtainGradientVisible"
          class="scrollable-table__bottom-curtain-gradient"
        ></div>
      </transition>

      <transition name="simple-fade-in-out-fast">
        <div
          v-if="entities.length > 4 && isLeftCurtainGradientVisible"
          class="scrollable-table__arrow-but scrollable-table__arrow-but--left"
          v-html="icons.interfaceElements.arrowLeft"
          @click="$refs.scrollableContainer.scrollLeft -= 100"
        ></div>
      </transition>
      <transition name="simple-fade-in-out-fast">
        <div
          v-if="entities.length > 4 && isRightCurtainGradientVisible"
          class="scrollable-table__arrow-but scrollable-table__arrow-but--right"
          v-html="icons.interfaceElements.arrowRight"
          @click="$refs.scrollableContainer.scrollLeft += 100"
        ></div>
      </transition>

      <!-- Main titles section -->
      <div :class="{ 'main-titles-section': true, 'scrollable-table__main-titles-section': true }">
        <div class="main-titles-section__main-title">Select Articles</div>
        <div class="main-titles-section__sub-title">
          Select which Articles to add each new
          {{ ['Tag', 'Season', 'Supplier'][[tagsMode, seasonsMode, suppliersMode].findIndex((item) => item)] }}
          to
        </div>
      </div>
      <!-- /main titles section -->

      <div
        class="scrollable-table__scrollable-container"
        @scroll="
          ($event) => {
            defineCurtainsVisibilityWithThrottle($event);
            setTableLeftScroll($event);
          }
        "
        ref="scrollableContainer"
      >
        <div class="scrollable-table__entities-row">
          <div
            v-for="(entity, index) in entities"
            :key="index"
            :class="{
              'entity-teaser': true,
              'scrollable-table__entity-teaser': true,
              'scrollable-table__entity-teaser--narrow': tagsMode || seasonsMode,
            }"
          >
            <!-- Supplier snippet -->
            <div
              v-if="suppliersMode"
              class="supplier-snippet entity-teaser__supplier-snippet scrollable-table__header-cell scrollable-table__header-cell--supplier"
            >
              <img
                class="supplier-snippet__flag"
                :src="$store.countries.getFlagPathFromName(entity.country)"
                :alt="entity.country"
              />
              <div class="supplier-snippet__supplier-name">{{ entity.name }}</div>
            </div>
            <!-- /supplier snippet -->

            <!-- Tag snippet -->
            <div
              v-if="tagsMode"
              class="tag-snippet entity-teaser__tag-snippet scrollable-table__header-cell scrollable-table__header-cell--tag"
            >
              <fs-pill-tag class="tag-snippet__tag-pill" :color="entity.color" clickable>
                {{ entity.name }}
              </fs-pill-tag>
            </div>
            <!-- /tag snippet -->

            <!-- Season snippet -->
            <div
              v-if="seasonsMode"
              class="season-snippet entity-teaser__season-snippet scrollable-table__header-cell scrollable-table__header-cell--season"
            >
              <fs-pill-season class="season-snippet__season-pill" :color="entity.color" clickable>
                {{ entity.name }}
              </fs-pill-season>
            </div>
            <!-- /season snippet -->

            <div class="entity-teaser__checkbox-wrap">
              <checkbox-custom
                class="entity-teaser__checkbox"
                large
                standalone
                :ref="'articleCheckbox-' + entity"
                plm
                @check="
                  tickedCheckboxes = Array.from(
                    new Set([
                      ...tickedCheckboxes,
                      ...articles.map((article) => `entity:${entity.id}-article:${article.id}`),
                    ])
                  )
                "
                @uncheck="
                  tickedCheckboxes = tickedCheckboxes.filter(
                    (item) => !new RegExp(`entity:${entity.id}[\w:-]+`).test(item)
                  )
                "
                :indeterminate="defineIsFewColumnItemsChecked(entity.id)"
                :checked="defineIsAllColumnItemsChecked(entity.id)"
              />
              <!--                @check="checkEntireColumn(columnItem.id)"-->
              <!--                @uncheck="uncheckEntireColumn(columnItem.id)"-->
              <!--                :indeterminate="defineIsSomeColumnItemsChecked(columnItem.id)"-->
              <!--                :checked="defineIsAllColumnItemsChecked(columnItem.id)"-->
            </div>
          </div>
        </div>

        <!-- Articles column -->
        <div class="articles-column scrollable-table__articles-column">
          <div
            v-for="(article, index) in articles"
            :key="index"
            :class="{
              'article-line': true,
              'article-line--hovered': hoveredTableLineId === `${article.id}${index}`,
              'articles-column__article-line': true,
            }"
            @mouseenter="hoveredTableLineId = `${article.id}${index}`"
            @mouseleave="hoveredTableLineId = null"
          >
            <photo-gallery
              v-if="article.images.length"
              class="article-line__image"
              :images="article.images"
              :save-func="() => {}"
              simplified-view
            />

            <fs-pill-color class="article-line__color" :color="article.color" />

            <div class="article-line__number">{{ article.articleNumber }}</div>

            <div class="article-line__bom">
              {{
                (article.principalMaterialIds.length &&
                  $store.componentTypes.getSubTypeFromId(article.principalMaterialIds[0].fullName)) ||
                '-'
              }}
            </div>

            <div class="article-line__genders">
              <div
                v-if="article.genders.includes(1)"
                class="article-line__gender-icon"
                v-html="icons.gender[genders[1].icon]"
              ></div>
              <div
                v-if="article.genders.includes(2)"
                class="article-line__gender-icon"
                v-html="icons.gender[genders[2].icon]"
              ></div>
              <div
                v-if="article.genders.includes(4)"
                class="article-line__gender-icon"
                v-html="icons.gender[genders[4].icon]"
              ></div>
              <div
                v-if="article.genders.includes(8)"
                class="article-line__gender-icon article-line__gender-icon--babies"
                v-html="icons.gender[genders[8].icon]"
              ></div>
            </div>

            <fs-pill-status class="article-line__status" :status="article.status" size="medium" />
          </div>
        </div>
        <!-- /articles column -->

        <div class="scrollable-table__checkboxes-list">
          <div
            v-for="(article, index1) in articles"
            :key="index1"
            class="checkboxes-line scrollable-table__checkboxes-line"
          >
            <div
              v-for="(checkbox, index2) in entities"
              :key="index2"
              :class="{
                'checkboxes-line__checkbox-wrap': true,
                'checkboxes-line__checkbox-wrap--narrow': tagsMode || seasonsMode,
                'checkboxes-line__checkbox-wrap--hovered': hoveredTableLineId === `${article.id}${index1}`,
              }"
              @mouseenter="hoveredTableLineId = `${article.id}${index1}`"
              @mouseleave="hoveredTableLineId = null"
            >
              <checkbox-custom
                class="checkboxes-line__checkbox"
                large
                standalone
                :ref="'articleCheckbox-' + checkbox.id"
                plm
                :checked="false"
                :value="`entity:${checkbox.id}-article:${article.id}`"
                v-model="tickedCheckboxes"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /scrollable table -->

    <cancel-n-action-btn-pair
      class="articles-n-checkboxes__manage-buttons-panel"
      @cancel="
        ($event) => {
          fsPopperClose($event);
          $emit('cancel', $event);
        }
      "
      @action="save"
      :is-save-in-progress="isSaveInProgress"
    />
  </div>
</template>

<script>
import { fsPopperClose } from '@components/poppers/fs-popper.vue';
import { genders } from '@lib/genders';
import throttle from 'lodash/throttle';
// Components
import CheckboxCustom from '@components/form-elements/checkbox-custom.vue';
import FsPillColor from 'apps/public/pages/components/pills/fs-pill-color.vue';
import FsPillSeason from '@components/pills/fs-pill-season.vue';
import PhotoGallery from '@components/photo-gallery/index.vue';
import FsPillStatus from '@components/pills/fs-pill-status.vue';
import FsPillTag from '@components/pills/fs-pill-tag.vue';
import CancelNActionBtnPair from '@components/cancel-n-action-btn-pair.vue';

// Icons
import genderIcons from '@lib/icons-imports/gender';
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// SVG
import crossClear from '@icons/other/cross-clear.svg';
import kebabDots from '@icons/other/kebab-dots-icon.svg';

export default {
  name: 'articles-n-checkboxes',

  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },

    entities: {
      type: Array,
      default: () => [],
    },

    entitiesIdsNotRequiringUpdating: {
      type: Array,
      default: () => [],
    },

    saveFunc: {
      type: Function,
      required: true,
    },

    tagsMode: { type: Boolean, default: false },
    seasonsMode: { type: Boolean, default: false },
    suppliersMode: { type: Boolean, default: false },
  },

  components: {
    CheckboxCustom,
    FsPillColor,
    FsPillSeason,
    PhotoGallery,
    FsPillStatus,
    FsPillTag,
    CancelNActionBtnPair,
  },

  data() {
    return {
      isLeftCurtainGradientVisible: false,
      isRightCurtainGradientVisible: true,
      isBottomCurtainGradientVisible: true,

      tickedCheckboxes: [],

      defineCurtainsVisibilityWithThrottle: () => {},

      isSaveInProgress: false,

      tableLeftScroll: 0,

      hoveredTableLineId: null,

      genders,

      icons: { gender: genderIcons, interfaceElements: interfaceElementsIcons },
      svg: { crossClear, kebabDots },
    };
  },

  mounted() {
    this.defineCurtainsVisibilityWithThrottle = throttle(this.defineCurtainsVisibility, 150, {
      leading: true,
      trailing: true,
    });

    setTimeout(() => {
      this.defineCurtainsVisibility();
    }, 1000);
  },

  updated() {},

  methods: {
    fsPopperClose,

    async save() {
      this.isSaveInProgress = true;

      // Retrieve articles|entities IDs from checkboxes names (example: "entity:7-article:53")
      const regexp = new RegExp('\\d+', 'g');
      const keyParisArray = this.tickedCheckboxes.map((item) => [...item.matchAll(regexp)].flat().map((itm) => +itm));

      // Data structure
      // tags = [{
      //   tagId: 123, // Required
      //   articleIds: [1, 2, 3] // Optionally/second step
      // }, ...]
      let matchingDataStructure = keyParisArray.reduce((acc, item, index) => {
        const existingElemIndex = acc.findIndex((itm) => itm.entityId === item[0]);

        if (existingElemIndex !== -1) {
          const newAcc = [...acc];
          newAcc[existingElemIndex].articleIds.push(item[1]);
          return newAcc;
        }
        return [...acc, { entityId: item[0], articleIds: [item[1]] }];
      }, []);

      // Define corresponding entityId fields: "supplierId", "tagId" or "seasonId"
      matchingDataStructure.forEach((item) => {
        if (this.tagsMode) item.tagId = item.entityId;
        if (this.seasonsMode) item.seasonId = item.entityId;
        if (this.suppliersMode) item.supplierId = item.entityId;
        delete item.entityId;
      });
      // Convert "entitiesIdsNotRequiringUpdating" from array of number to array of objects
      const entitiesNotRequiringUpdating = this.entitiesIdsNotRequiringUpdating.map((item) => {
        if (this.tagsMode) return { tagId: item };
        if (this.seasonsMode) return { seasonId: item };
        if (this.suppliersMode) return { supplierId: item };
      });

      // Update tags, seasons or suppliers
      await this.saveFunc([...matchingDataStructure, ...entitiesNotRequiringUpdating]);

      this.isSaveInProgress = false;

      this.$emit('close');
    },

    defineIsAllColumnItemsChecked(entityId) {
      const itemsNumber = this.defineHowManyItemsChecked(entityId);
      return itemsNumber === this.articles.length;
    },

    defineIsFewColumnItemsChecked(entityId) {
      const itemsNumber = this.defineHowManyItemsChecked(entityId);
      return itemsNumber > 0 && itemsNumber !== this.articles.length;
    },

    defineHowManyItemsChecked(entityId) {
      const regexp = new RegExp(`entity:${entityId}[\w:-]+`);
      return this.tickedCheckboxes.filter((item) => regexp.test(item)).length;
    },

    setTableLeftScroll(event) {
      this.tableLeftScroll = event?.target.scrollLeft;
    },

    defineCurtainsVisibility(event) {
      const blockWidth = event?.target.clientWidth || this?.$refs.scrollableContainer?.clientWidth - 18;
      const scrollWidth = event?.target.scrollWidth || this?.$refs.scrollableContainer?.scrollWidth;

      const blockHeight = event?.target.clientHeight || this?.$refs.scrollableContainer?.clientHeight - 18;
      const scrollHeight = event?.target.scrollHeight || this?.$refs.scrollableContainer?.scrollHeight;

      const scrollLeft = event?.target.scrollLeft || this?.$refs.scrollableContainer?.scrollLeft;
      const maxScrollLeft = scrollWidth - blockWidth;

      const scrollTop = event?.target.scrollTop || this?.$refs.scrollableContainer?.scrollTop;
      const maxScrollTop = scrollHeight - blockHeight;

      if (scrollWidth <= blockWidth) {
        this.isLeftCurtainGradientVisible = false;
        this.isRightCurtainGradientVisible = false;
      } else {
        this.isLeftCurtainGradientVisible = scrollLeft > 15;
        this.isRightCurtainGradientVisible = scrollLeft < maxScrollLeft - 15;
      }

      if (scrollHeight <= blockHeight) {
        this.isBottomCurtainGradientVisible = false;
      } else {
        this.isBottomCurtainGradientVisible = scrollTop < maxScrollTop - 15;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

$table-left-column-width: math.div(67.5rem, 1.6);
$table-header-height: math.div(13.3rem, 1.6);
$table-header-height-short: math.div(10.3rem, 1.6);
$table-column-width: math.div(13rem, 1.6);
$table-column-width-narrow: math.div(9rem, 1.6);
$table-line-height: math.div(6rem, 1.6);

// ============================================================================
// ============================================================================
// Add size menu
.add-size-menu {
  width: math.div(17.4rem, 1.6);
  padding: math.div(1.4rem, 1.6) math.div(1rem, 1.6);
  margin: 0;
  box-sizing: border-box;
  list-style: none;

  &__option {
    height: math.div(2.5rem, 1.6);
    padding-left: math.div(0.9rem, 1.6);
    margin-bottom: 1px;
    box-sizing: border-box;
    border-radius: math.div(0.5rem, 1.6);
    display: flex;
    align-content: center;
    align-items: center;
    font-size: math.div(1.4rem, 1.6);
    background: rgba(53, 73, 94, 0);
    transition: background-color 0.1s ease;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(53, 73, 94, 0.08);
    }
  }

  &__icon {
    width: math.div(1.5rem, 1.6);
    height: math.div(1.5rem, 1.6);
    margin-right: math.div(1.1rem, 1.6);

    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }

  &__text {
    padding-top: 1px;
  }
}

// ============================================================================
// ============================================================================
// Supplier snippet
.supplier-snippet {
  width: math.div(12rem, 1.6);
  padding-bottom: math.div(0.7rem, 1.6);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  &__flag {
    width: math.div(2.2rem, 1.6);
    height: math.div(1.5rem, 1.6);
    margin-bottom: math.div(1rem, 1.6);
  }

  &__supplier-name {
    height: math.div(3.5rem, 1.6);
    position: relative;
    overflow: hidden;
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.7rem, 1.6);
    text-align: center;
  }

  &__checkbox-wrap {
    display: flex;
    justify-content: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
  }
}

// ============================================================================
// ============================================================================
// Tag snippet
.tag-snippet {
  width: math.div(7.8rem, 1.6);
  padding-bottom: math.div(0.7rem, 1.6);

  &__tag-pill {
    max-width: math.div(7.4rem, 1.6);
    margin-bottom: math.div(0.5rem, 1.6);
  }

  &__checkbox-wrap {
    display: flex;
    justify-content: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
  }
}

// ============================================================================
// ============================================================================
// Season snippet
.season-snippet {
  width: math.div(7.8rem, 1.6);
  padding-bottom: math.div(0.7rem, 1.6);

  &__season-pill {
    max-width: math.div(7.4rem, 1.6);
    margin-bottom: math.div(0.5rem, 1.6);
  }

  &__checkbox-wrap {
    display: flex;
    justify-content: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
  }
}

// ============================================================================
// ============================================================================
// Entity teaser
.entity-teaser {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  position: relative;
  font-size: math.div(1.2rem, 1.6);
  line-height: math.div(1.5rem, 1.6);
  background: #fff;
  user-select: none;

  &__supplier-snippet,
  &__tag-snippet,
  &__season-snippet {
    max-width: 100%;
  }

  &__checkbox-wrap {
  }

  &__checkbox {
    margin-top: -2px;
    font-size: math.div(1.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Checkboxes line
.checkboxes-line {
  height: $table-line-height;
  //padding-left: $table-left-column-width;
  display: flex;
  position: relative;

  //&:first-child {
  //  height: $table-line-height + math.div(.6rem, 1.6);
  //  padding-top: math.div(.6rem, 1.6);
  //}

  &__checkbox-wrap {
    width: $table-column-width;
    min-width: $table-column-width;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &--hovered {
      background: #fafafa;
    }

    &--narrow {
      width: $table-column-width-narrow;
      min-width: $table-column-width-narrow;
    }
  }

  &:first-child &__checkbox-wrap {
    height: $table-line-height + math.div(0.6rem, 1.6);
    padding-top: math.div(0.6rem, 1.6);
    margin-top: math.div(-0.6rem, 1.6);
  }

  &__checkbox {
    font-size: math.div(1.5rem, 1.6);
  }
}

// ============================================================================
// ============================================================================
// Main titles section
.main-titles-section {
  padding: math.div(2.2rem, 1.6) 0 math.div(1rem, 1.6) math.div(3.6rem, 1.6);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  background: white;

  &__main-title {
    margin-bottom: math.div(0.4rem, 1.6);
    font: #{math.div(2rem, 1.6)}/#{math.div(2.6rem, 1.6)} 'Montserrat', sans-serif;
  }

  &__sub-title {
    font-size: math.div(1.4rem, 1.6);
    line-height: math.div(1.9rem, 1.6);
    color: rgba(53, 73, 94, 0.5);
  }
}

// ============================================================================
// ============================================================================
// Articles column
.articles-column {
  display: flex;
  flex-direction: column;

  &__article-line {
    height: $table-line-height;
    background: white;
  }
  .article-line {
    padding-left: math.div(3.6rem, 1.6);
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-size: math.div(1.4rem, 1.6);

    &--hovered {
      background: #fafafa;
    }

    &__image {
      width: math.div(7.2rem, 1.6);
      height: math.div(7.2rem, 1.6);
      margin-right: math.div(1.1rem, 1.6);
      mix-blend-mode: multiply;
    }

    &__color {
      width: math.div(7.5rem, 1.6);
      margin: 0 math.div(1.1rem, 1.6);
    }

    &__number {
      width: math.div(9.5rem, 1.6);
      height: math.div(1.6rem, 1.6);
      margin: 0 math.div(1.1rem, 1.6);
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    &__bom {
      width: math.div(9.5rem, 1.6);
      height: math.div(1.6rem, 1.6);
      margin: 0 math.div(1.1rem, 1.6);
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    &__genders {
      width: math.div(7.2rem, 1.6);
      height: math.div(3.5rem, 1.6);
      margin: 0 math.div(1.1rem, 1.6);
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    &__gender-icon {
      width: math.div(1.8rem, 1.6);
      height: math.div(1.8rem, 1.6);
      margin: 0 2px;
      position: relative;
      fill: rgb(53, 73, 94);

      &--babies {
        top: 2px;
      }
    }

    &__status {
      width: math.div(10.5rem, 1.6);
      margin: 0 0 0 math.div(1.1rem, 1.6);
    }
  }
}

// ============================================================================
// ============================================================================
// Scrollable table
.scrollable-table {
  width: 100%;
  padding-top: math.div(0.8rem, 1.6);
  box-sizing: border-box;
  border-radius: math.div(1.2rem, 1.6) math.div(1.2rem, 1.6) 0 0;
  display: flex;
  position: relative;
  overflow: hidden;
  //outline: 1px #f00 solid;

  &::before {
    content: '';
    width: calc(100% - #{math.div(1.8rem, 1.6)});
    height: 1px;
    position: absolute;
    top: calc(#{$table-header-height} + #{math.div(0.8rem, 1.6)});
    left: 0;
    z-index: 6;
    font-size: 0;
    line-height: 0;
    background: rgba(53, 73, 94, 0.05);
  }

  &--short-header {
    &::before {
      top: calc(#{$table-header-height-short} + #{math.div(0.8rem, 1.6)});
    }
  }

  &__arrow-but {
    width: math.div(1.2rem, 1.6);
    height: math.div(1.2rem, 1.6);
    position: absolute;
    top: math.div(5.7rem, 1.6);
    z-index: 5;
    cursor: pointer;
    user-select: none;

    &::before {
      content: '';
      width: calc(100% + #{math.div(1.9rem, 1.6)});
      height: calc(100% + #{math.div(1.9rem, 1.6)});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--left {
      left: calc(#{$table-left-column-width} + #{math.div(1.5rem, 1.6)});
    }
    &--right {
      right: math.div(2.5rem, 1.6);
    }

    ::v-deep svg {
      fill: rgba(53, 73, 94, 0.5);
      transition: fill 0.15s ease;
    }

    &:hover ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }

  &__left-curtain-gradient,
  &__right-curtain-gradient {
    width: math.div(10.5rem, 1.6);
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    pointer-events: none;
  }
  &__left-curtain-gradient {
    width: math.div(12.5rem, 1.6);
    left: $table-left-column-width;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
  }
  &__right-curtain-gradient {
    right: math.div(1.8rem, 1.6);
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
  }

  &__bottom-curtain-gradient {
    width: calc(100% - #{math.div(1.8rem, 1.6)});
    height: math.div(7.5rem, 1.6);
    position: absolute;
    inset: auto auto 0 0;
    z-index: 5;
    background: rgba(255, 255, 255, 0.2);
    background: linear-gradient(0, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &__scrollable-container {
    width: calc(100% - #{math.div(1rem, 1.6)});
    height: calc(100% + #{math.div(0.9rem, 1.6)});
    position: relative;
    overflow: scroll;
    scrollbar-color: rgba(0, 0, 0, 0.15);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: math.div(0.8rem, 1.6);
      height: math.div(0.8rem, 1.6);
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: math.div(10rem, 1.6);
      background: rgba(0, 0, 0, 0.12);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  //&__scrollable-container-content {}

  &__main-titles-section {
    width: $table-left-column-width;
    height: calc(#{$table-header-height} + #{math.div(0.8rem, 1.6)});
    margin-bottom: -$table-header-height;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }

  &--short-header {
    .scrollable-table__main-titles-section {
      height: calc(#{$table-header-height-short} + #{math.div(0.8rem, 1.6)});
      margin-bottom: -$table-header-height-short;
    }
  }

  &__entities-row {
    height: $table-header-height;
    padding-left: $table-left-column-width;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  &--short-header {
    .scrollable-table__entities-row {
      height: $table-header-height-short;
    }
  }

  &__entity-teaser {
    width: $table-column-width;
    min-width: $table-column-width;
    height: 100%;

    &--narrow {
      width: $table-column-width-narrow;
      min-width: $table-column-width-narrow;
    }
  }

  &__articles-column {
    width: $table-left-column-width;
    position: sticky;
    left: 0;
    z-index: 2;
  }

  &__checkboxes-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $table-header-height;
    left: $table-left-column-width;
    z-index: 1;
  }

  &--short-header {
    .scrollable-table__checkboxes-list {
      top: $table-header-height-short;
    }
  }

  &__checkboxes-line {
  }
}

// ============================================================================
// ============================================================================
// Articles & Checkboxes section
$entire-section-width: math.div(111rem, 1.6);
$entire-section-height: math.div(60rem, 1.6);

.articles-n-checkboxes {
  width: $entire-section-width;
  height: $entire-section-height;
  display: flex;
  flex-direction: column;

  &--1-line-with-short-header {
    height: calc(#{$entire-section-height} - #{math.div(25.5rem, 1.6)});
  }
  &--2-lines-with-short-header {
    height: calc(#{$entire-section-height} - #{math.div(19rem, 1.6)});
  }
  &--3-lines-with-short-header {
    height: calc(#{$entire-section-height} - #{math.div(14rem, 1.6)});
  }
  &--4-lines-with-short-header {
    height: calc(#{$entire-section-height} - #{math.div(8rem, 1.6)});
  }
  &--5-lines-with-short-header {
    height: calc(#{$entire-section-height} - #{math.div(2rem, 1.6)});
  }
  &--6-lines-with-short-header {
    height: calc(#{$entire-section-height} + #{math.div(1rem, 1.6)});
  }

  &--1-line {
    height: calc(#{$entire-section-height} - #{math.div(25.5rem, 1.6)} + #{math.div(3rem, 1.6)});
  }
  &--2-lines {
    height: calc(#{$entire-section-height} - #{math.div(19rem, 1.6)} + #{math.div(3rem, 1.6)});
  }
  &--3-lines {
    height: calc(#{$entire-section-height} - #{math.div(14rem, 1.6)} + #{math.div(3rem, 1.6)});
  }
  &--4-lines {
    height: calc(#{$entire-section-height} - #{math.div(8rem, 1.6)} + #{math.div(3rem, 1.6)});
  }
  &--5-lines {
    height: calc(#{$entire-section-height} - #{math.div(2rem, 1.6)} + #{math.div(3rem, 1.6)});
  }
  &--6-lines {
    height: calc(#{$entire-section-height} + #{math.div(1rem, 1.6)} + #{math.div(3rem, 1.6)});
  }

  &--1-narrow-elements {
    width: calc(#{$entire-section-width} - #{math.div(31.5rem, 1.6)});
  }
  &--2-narrow-elements {
    width: calc(#{$entire-section-width} - #{math.div(22rem, 1.6)});
  }
  &--3-narrow-elements {
    width: calc(#{$entire-section-width} - #{math.div(14rem, 1.6)});
  }

  &--1-wide-element {
    width: calc(#{$entire-section-width} - #{math.div(27rem, 1.6)});
  }
  &--2-wide-elements {
    width: calc(#{$entire-section-width} - #{math.div(14rem, 1.6)});
  }
  &--3-wide-elements {
    width: calc(#{$entire-section-width} - #{math.div(1rem, 1.6)});
  }

  &__scrollable-table {
    flex-grow: 1;
  }

  &__manage-buttons-panel {
    height: math.div(13rem, 1.6);
    min-height: math.div(13rem, 1.6);
  }
}
</style>
